@font-face {
  font-family: 'Helvetica Neue';
  src: url('helveticaneuecyr-bold-webfont.woff2') format('woff2'),
    url('helveticaneuecyr-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('helveticaneuecyr-light-webfont.woff2') format('woff2'),
    url('helveticaneuecyr-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('helveticaneuecyr-medium-webfont.woff2') format('woff2'),
    url('helveticaneuecyr-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('helveticaneuecyr-roman-webfont.woff2') format('woff2'),
    url('helveticaneuecyr-roman-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.backBtnContainer.hide {
  display: none;
}

.backBtn {
  display: none;
  cursor: pointer;
  /*box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);*/
  border-radius: 20px;

  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.backBtn.autoPosition {
  position: absolute;
  left: 20px;
  top: 140px;
}

.backBtn.hide {
  display: none;
}

.backBtn.active {
  display: block;
}

.backBtn:hover svg path {
  stroke: #000;
  /*box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);*/
}
.backBtnNav,
.backBtnNav.active {
  display: none;
  cursor: pointer;
}

@media only screen and (max-width: 870px) {
  .backBtnContainer {
    display: none;
  }
  .backBtnContainer.active {
    /*margin-right: 20px;*/
    display: block;
  }
  .backBtn.active {
    position: relative;
    top: 0;
    box-shadow: none;
    display: none;
  }

  .backBtnNav {
    display: none;
  }
  .backBtnNav.active {
    position: relative;
    top: 0;
    box-shadow: none;
    display: block;
  }

  svg.backBtn.active {
    margin-left: -15px;
  }

  .hide {
    display: block;
  }
}

/* iphone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) {
  .backBtnContainer.active {
    width: 36px;
    height: 36px;
    margin-right: 5px;
    display: block;
  }
  svg.backBtn.active {
    margin-left: -25px;
  }
}
